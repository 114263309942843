import banner1Dsktp from '../../assets/img/desktop/banner-slide-1.png';
import banner1Mobile from '../../assets/img/mobile/banner-slide-1-mobile.png';
import banner2Dsktp from '../../assets/img/desktop/banner-slide-2.png';
import banner2Mobile from '../../assets/img/mobile/banner-slide-2-mobile.png';
import logoCartao from '../../assets/svgs/cdtteam-logo-large.svg';
import cartaoModal from '../../assets/img/desktop/cdtteam-mockup-modal.png';
import bgPrecoDesk from '../../assets/img/desktop/cdtteam-bg-preco-desktop.png';
import bgPrecoMobile from '../../assets/img/mobile/cdtteam-bg-preco-mobile.png';
import cartaoPreco from '../../assets/img/desktop/cdtteam-mockup-preco.png';
import teamShield from '../../assets/svgs/cdtteam-shield.svg';

import bgFaqDesk from '../../assets/img/desktop/cdtteam-bg-faq-desktop.png';
import bgFaqMobile from '../../assets/img/mobile/cdtteam-bg-faq-mobile.png';
import bgBeneficioDesk from '../../assets/img/desktop/cdtteam-bg-beneficio-desktop.png';
import bgBeneficioMobile from '../../assets/img/mobile/cdtteam-bg-beneficio-mobile.png';

const CARD_MONTHLY_FEE = "R$ 29,70"
const APPOINTMENT_GP = "R$ 26"
const APPOINTMENT_SP = "R$ 35"

const TEAM_NAME = "Fluxo";

const SALES_URL = "https://vendas.cartaodetodosfluxo.com.br"
const TWITTER_URL = "https://twitter.com/fluxogg"
const INSTAGRAM_URL = "https://www.instagram.com/fluxogg/"
const FACEBOOK_URL = "https://www.facebook.com/ofluxogg"
const SITE_URL = "https://www.cartaodetodos.com.br/"
export const TEAM_ID = 127; // Flamengo Rio de Janeiro, RJ
export const VENUE_ID = 204; // Maracanã

const API_ENDPOINT = "https://api.cdt.casadebraganca.com";
export const API_LIST_STATES_BY_CATEGORY = `${API_ENDPOINT}/ListarEstadosPorCategoria`;
export const API_FILTER_PARTNERS = `${API_ENDPOINT}/FiltrarParceiros`;
export const API_LIST_CATEGORIES = `${API_ENDPOINT}/ListarCategorias`;
export const NEXT_MATCH_API = "https://api-football-v1.p.rapidapi.com/v3/fixtures";

export const RAPID_API_KEY = "5caa97f7dbmsh686b6ad0475fbd8p1b8ee2jsnef9dc411921e";
export const RAPID_API_HOST = "api-football-v1.p.rapidapi.com";

export const staticCampaignData = {
  teamShield,
  logoCartao: logoCartao,
  teamName: TEAM_NAME,
  salesUrl: SALES_URL,
  prices: {
    monthlyFee: CARD_MONTHLY_FEE,
    appointment: {
      general: APPOINTMENT_GP,
      specialist: APPOINTMENT_SP,
    }
  },
  banners: [
    {
      img: { desktop: banner1Dsktp, mobile: banner1Mobile },
      title: "Campeão na saúde",
      subtitle: `Consultas com clínico geral por ${APPOINTMENT_GP} e demais especialidades, ${APPOINTMENT_SP} por apenas ${CARD_MONTHLY_FEE} mensais para toda a família.`
    },
    {
      img: { desktop: banner2Dsktp, mobile: banner2Mobile },
      title: "Especiais",
      subtitle: `Em breve compartilharemos descontos em benefícios especiais  para os filiados ao Cartão de TODOS ${TEAM_NAME}.` 
    },
  ],
  modal: {
    img: cartaoModal,
    title: "Bem-vindo!",
    description: `Maior cartão de descontos do Brasil, o Cartão de TODOS agora também é o cartão da Família Fluxo, oferecendo acesso a serviços de saúde, educação e lazer por apenas ${CARD_MONTHLY_FEE} mensais para todos da família.`
  },
  imgs: {
    preco: { bgDesktop: bgPrecoDesk, bgMobile: bgPrecoMobile, cartaoPreco },
    faq: { bgDesktop: bgFaqDesk, bgMobile: bgFaqMobile },
    beneficio: { bgDesktop: bgBeneficioDesk, bgMobile: bgBeneficioMobile }
  },
  socials: {
    site: SITE_URL,
    twitter: TWITTER_URL,
    facebook: FACEBOOK_URL,
    instagram: INSTAGRAM_URL,
  }
} as const;
