import styled from "styled-components";

export const Button = styled.a`
    line-height: normal !important;
    margin-top: -12px;
    button {
        margin-right: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`

export const Footer = styled.div`
    background-color: var(--primary);
    padding-top: 2rem;
    text-align: center;

    img {
        max-width: 4rem;
    }
    .social {
        img {
            margin: 0 0.3rem;
        }
    }
    @media (max-width: 768px){
        padding-top: 3.125rem;
    }
    div.col-md-3 > img {
        transform: scale(1.4);
        margin-bottom: 1rem;
    }
    p{
        font-family: 'ubuntu';
        font-weight: 300;
        color: var(--secondary-text);
        font-size: 15px;
        line-height: 17px;
        @media (max-width: 768px){
            margin-bottom: 5px;
            margin-top: 1.25rem;
        }
    }
    a{
        font-family: 'ubuntu';
        font-weight: bold;
        color: var(--secondary-text);
        font-size: 34px;
        line-height: 39px;
        text-decoration: none;
    }
    .social{
        padding-top: 30px;
        img{
            margin: 0px 10px;
        }
    }
    .txt-footer{
        border-bottom: solid 1px var(--secondary-text);
        margin-top: 30px;
        padding: 15px 0;
        p{
            text-align: justify;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 100;
            color: var(--secondary-text);
            font-size: 0.813rem;
            line-height: 17px;
        }
        @media (max-width: 768px){
            padding: 15px;
            width: 95%;
            margin: 0 auto;
            p{
                text-align: center;
            }
        }
    }
    .credits{
        display: flex;
        justify-content: center;
        padding: 2rem;
        margin-bottom: 2rem;
        position: relative;
        flex-wrap: wrap;
        @media (max-width: 768px){
            justify-content: flex-start;
            padding-left: 1rem;
            margin-bottom: 5rem;
            p{
                display: inline-block;
                width: 36%;
                margin-top: 0;
            }
        }
        a {
            display: inline-block;
        button{
            min-width: 200px;
            min-height: 3rem;
            font-family: 'ubuntu';
            font-weight: 500;
            font-size: 14px;
            border-radius: 30px;
            position: relative;
            background-color: white;
            color: #5f00e6;
            bottom: 6px;
            margin-left: 10px;
                text-align: center;
            @media (max-width: 768px){
                    min-width: 200px;
                }
            }
            &.made-by {
                position: absolute;
                right: 0;
            }
            @media (max-width: 80em) {
                &.made-by {
                    margin-top: 3rem;
                }
                text-align: unset;
                width: 100%;
            }
        }
        .main-credits {
            width: 80%;
            display: flex;
            position: absolute;
            text-align: center;
            justify-content: center;
            a {
                margin-top: 0;
            }
            @media (max-width: 80em) {
                display: block;
                position: relative;
                width: 75%;
                a {
                    width: 42%;
                }
            }
        }
        img{
            margin-top: 0%;
            @media (max-width: 80em) {
                margin-top: 3rem;
            }
        }
        p:nth-child(1) {
            line-height: 2.5rem;
        }
    }
    @media (max-width: 80em) {
        position: relative;
        z-index: 20;
        padding-top: 7rem;
        margin-top: -8rem;
        }

    @media (max-width: 48em) {
        z-index: 30;
        padding-top: 20vw;
        margin-top: -22vw;
    }
`;