import React, { Children, ReactElement, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { IndicatorBar, IndicatorBase, IndicatorContainer } from './style';

const REFRESH_PROGRESS_BAR = 100;

function CarouselWrapper({ children, interval }: { children: ReactElement | ReactElement[], interval: number }) {
  const quantityBanner = Children.count(children);
  const [progress, setProgress] = useState<number>(0);
  const [bannerIdxActive, setBannerIdxActive] = useState(0);
  const progressRef = useRef<NodeJS.Timer>();
  
  useEffect(() => {
    const progress = setInterval(() => {
      setProgress((oldValue) => {
        const newValue = oldValue + (REFRESH_PROGRESS_BAR / interval);
        const secondsElapsed = newValue * interval

        if(secondsElapsed >= interval) {
          if (bannerIdxActive < quantityBanner - 1) {
            setBannerIdxActive(bannerIdxActive + 1);
            clearInterval(progress);
            return 0;
          } else {
            setBannerIdxActive(0);
            clearInterval(progress);
            return 0;
          }
        }
        
        return newValue;
      });
    }, REFRESH_PROGRESS_BAR);
    progressRef.current = progress;
    return () => clearInterval(progress);
  }, [bannerIdxActive, interval, progress, quantityBanner]);
  
  const renderIndicators = () => {
    const indicators = Array.from(Array(quantityBanner)).map((_indicator, index) => (
        <IndicatorBase isActive={ bannerIdxActive === index } key={index} onClick={() => { setBannerIdxActive(index); clearInterval(progressRef.current); setProgress(0); }}>
          <IndicatorBar isActive={ bannerIdxActive === index } progress={progress} />
        </IndicatorBase>
      ));
  
      return (<IndicatorContainer>{indicators}</IndicatorContainer>);
    }
    
    return (
      <>
        <Carousel fade indicators={false} controls={false} activeIndex={bannerIdxActive}>
          {children}
          {renderIndicators()}
        </Carousel>
      </>
      );
    };
    
    export default React.memo(CarouselWrapper);